<template>
  <div class="login-component">
    <!-- 手机号和图形验证码页面 -->
    <div class="form-container" v-show="!showTelCodeContainer">
      <van-form
        key="loginForm"
        class="register-form"
        ref="loginForm"
        validate-first
        :show-error="false"
      >
        <van-field
          name="account"
          :placeholder="isZdd ? '请输入手机号' : '请输入手机号/邮箱'"
          ref="telephone"
          :clearable="true"
          :show-error="false"
          :error="false"
          :rules="rules.account"
          v-model="loginModel.account"
          @blur="setLoginCode"
        ></van-field>
        <van-field
          name="imgCode"
          type="text"
          ref="imgCode"
          placeholder="请输入图片验证码"
          :maxlength="4"
          :rules="rules.imgCode"
          v-model="checkModel.imgCode"
        >
          <template #button>
            <div class="imgCode-container" @click="getPictureCode">
              <img :src="pictureCode.img" alt="imgCode" />
            </div>
          </template>
        </van-field>
        <p class="description">
          未注册的手机号/邮箱将自动注册为{{ systemInfo.company }}账户
        </p>
        <div class="agreement-list">
          点击登录代表您已阅读并同意<a
            class="link agreement-link"
            v-for="item in agreementList"
            :key="item.title"
            @click="handleShowAgreement(item.title)"
            >《{{ item.title }}》</a
          >
        </div>
      </van-form>
    </div>
    <!-- 手机号和图形验证码页面 -->

    <!-- 验证码登录页面 -->
    <div class="tel-code-container" v-show="showTelCodeContainer">
      <div class="tel-code-title">您正在登录{{ systemInfo.company }}</div>
      <div class="tel-code-phone">
        <span class="text">已发送验证码到</span>
        <span class="text bold">{{ loginModel.account }}</span>
      </div>
      <div
        class="tel-code-input"
        ref="telCodeInput"
        @click="handleFocusLoginCodeInput"
      >
        <span
          class="tel-code-val"
          :class="{ highlight: isCurInputSpan(index) }"
          v-for="(item, index) in 6"
          :key="index"
        >
          {{ charAtCode(loginModel.code, index) }}
        </span>
        <input
          class="login-code-input"
          type="tel"
          maxlength="6"
          inputmode="numeric"
          ref="loginCodeInput"
          @input="onChange"
        />
      </div>
      <div
        class="get-code"
        :class="{ disabled: isTelCodeButtonBanned }"
        @click="getTelCode"
        v-if="!getTelCodeButtonLoading"
      >
        {{ telCodeButtonText }}
      </div>
    </div>
    <!-- 验证码登录页面 -->

    <div class="button-container" v-if="!isPopup">
      <!-- 验证手机号和图形验证码 -->
      <van-button
        type="primary"
        round
        :loading="checkLoading"
        v-if="!showTelCodeContainer"
        @click="checkAccountAndPictureCode"
        >登录</van-button
      >
      <!-- 验证手机号和图形验证码 -->
    </div>
    <Agreement v-model="showAgreement" :title="curAgreementTitle" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { validateMobileNumber, validateEmail } from '@/utils/validate'
  import api from '@/api/passport'
  import Agreement from '@/components/Agreement/index.vue'
  import { SYSTEM_ID } from '@/common/enum'
  import archivesApi from '@/api/user/archives'

  export default {
    name: 'CodeLogin',
    components: { Agreement },
    inject: ['handleToggleShowDescription'],
    beforeRouteEnter(to, from, next) {
      const formPath = from.path
      next(vm => {
        vm.formPath = formPath
      })
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
      redirect: {
        type: String,
      },
      otherQuery: {
        type: null,
      },
    },
    watch: {
      showTelCodeContainer: {
        handler(val) {
          if (val) {
            this.handleToggleShowDescription(0)
          } else {
            this.handleToggleShowDescription(1)
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
      userInfo() {
        return this.$store.getters.getUserInfo
      },
    },
    data() {
      this.agreementList = [
        {
          title: '服务协议',
        },
        {
          title: '隐私协议',
        },
        {
          title: '证书服务协议',
        },
        {
          title: '易云章企业与个人服务协议',
        },
      ]
      return {
        formPath: '',
        rules: {
          account: [
            {
              validator: this.validateAccount,
              trigger: 'onBlur',
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号',
              trigger: 'onBlur',
            },
          ],
        },
        // 图片验证码
        pictureCode: {
          // 验证码图片base64
          img: undefined,
          // 对应的验证码
          code: undefined,
        },
        checkModel: {
          code: undefined,
          imgCode: undefined,
        },
        loginModel: {
          account: undefined,
          code: undefined,
          openId: window.sessionStorage.openId
            ? window.sessionStorage.openId
            : null,
        },
        // 获取图形验证码loading
        getImgCodeLoading: false,
        // 密码是否展示明文
        showPassword: false,
        loginButtonLoading: false,
        // 账号注册信息的错误
        isCheckError: false,
        // 获取验证码的loading
        getTelCodeButtonLoading: false,
        // 注册按钮的loading
        registerButtonLoading: false,
        // 是否禁用获取验证码按钮
        isTelCodeButtonBanned: false,
        // 获取验证码按钮定时器
        getTelCodeTimer: null,
        telCodeButtonText: '获取验证码',
        getTelCodeCountDown: 60,
        // 校验中
        checkLoading: false,
        // 展示输入验证码页面
        showTelCodeContainer: false,
        curAgreementTitle: '服务协议',
        showAgreement: false,

        code: undefined,
      }
    },
    created() {
      const { code } = this.$route.query
      this.code = code

      this.fetchData()
    },
    mounted() {
      this.loginModel.account = this.$store.getters.loginCode

      const { telCodeInput } = this.$refs
      window.addEventListener('resize', () => {
        telCodeInput.scrollIntoView({ behavior: 'smooth' })
      })
    },
    methods: {
      setLoginCode() {
        this.$store.commit('setLoginCode', this.loginModel.account)
      },
      handleShowAgreement(title) {
        this.showAgreement = true
        this.curAgreementTitle = title
      },
      async fetchData() {
        await this.getPictureCode()
      },
      // 获取图片验证码
      async getPictureCode() {
        try {
          if (this.getImgCodeLoading) return
          this.getImgCodeLoading = true
          const { code, img } = await api.getPictureCode()
          this.pictureCode = {
            ...this.pictureCode,
            code,
            img,
          }
          this.checkModel.code = code
        } catch (error) {
          console.error(error)
        } finally {
          this.getImgCodeLoading = false
        }
      },

      // 验证账号
      validateAccount(val) {
        if (this.isZdd) {
          if (this.isMobile(val)) {
            return this.validateMobile(val)
          }
          return false
        }
        if (this.isMobile(val)) {
          return this.validateMobile(val)
        }
        if (this.isEmail(val)) {
          return this.validateEmailAccount(val)
        }
        return false
      },

      // 验证手机号
      validateMobile(val) {
        return validateMobileNumber(val)
      },
      // 验证邮箱
      validateEmailAccount(val) {
        return validateEmail(val)
      },

      // 验证表单项
      async validateField(formName, name) {
        let isPassed = true
        try {
          await this.resetValidation(formName, name)
          await this.$refs[formName].validate(name)
        } catch (error) {
          isPassed = false
          if (error) {
            this.$toast(error)
          }
        }
        return isPassed
      },

      // 重置表单项的验证提示
      async resetValidation(formName, name) {
        try {
          await this.$refs[formName].resetValidation(name)
        } catch (error) {
          if (error) {
            this.$toast(error)
          }
        }
      },

      // 验证账号是否注册和图片验证码
      async checkAccountAndPictureCode() {
        try {
          const isPassed = await this.validateField('loginForm', 'account')
          if (!isPassed) return
          if (this.checkLoading) return
          this.checkLoading = true
          this.isCheckError = false
          const { account } = this.loginModel

          const model = {
            telephone: account,
            code: this.pictureCode.code,
            imgCode: this.checkModel.imgCode,
          }
          const res = await api.checkAccountAndPictureCode(model)
          const { verifyCodeStatus } = res
          if (!verifyCodeStatus) {
            this.$toast('图形验证码错误')
            this.isCheckError = true
            await this.getPictureCode()
            return
          }

          // 如果图形验证码通过则进入输入验证码页面
          this.showTelCodeContainer = true
          this.handleFocusLoginCodeInput()
          if (this.isMobile(account)) {
            await this.getTelCode()
          } else if (this.isEmail(account)) {
            await this.getEmailCode()
          }
        } catch (error) {
          this.isCheckError = true
          await this.getPictureCode()
          console.error(error)
        } finally {
          this.checkLoading = false
        }
      },

      // 让输入框聚焦
      handleFocusLoginCodeInput() {
        console.log('让输入框聚焦');
        this.$nextTick(() => {
          const { loginCodeInput } = this.$refs
          loginCodeInput.focus()
        })
      },

      async onChange(e) {
        // console.log('e: ',e);
        const { target } = e
        const { value } = target
        this.loginModel.code = value
        if (this.loginModel.code.length === 6) {
          target.blur()
          if (this.redirect) {
            console.log('handleShareLogin');
            await this.handleShareLogin()
          } else {
            console.log('handleLogin');
            await this.handleLogin()
          }
        }
      },

      // 验证输入的值是手机还是邮箱
      isMobile(value) {
        return /^\d+$/g.test(value)
      },
      isEmail(value) {
        return /[a-zA-Z@]/g.test(value)
      },

      // 截取code中的字符
      charAtCode(code, index) {
        if (code) {
          return code.toString()[index]
        }
        return undefined
      },

      // 输入验证码框 是否为当前的span
      isCurInputSpan(index) {
        if (this.loginModel.code) {
          const len = this.loginModel.code.toString().length
          if (index + 1 === len) {
            return true
          }
        } else {
          if (index === 0) {
            return true
          }
        }

        return false
      },

      // 获取手机验证码
      async getTelCode() {
        try {
          // 在倒计时直接跳出
          if (this.getTelCodeTimer) return
          if (!this.isCheckError) {
            this.getTelCodeButtonLoading = true
            const model = {
              telephone: this.loginModel.account,
              type: 16, // 16 验证码登录
              systemId: this.systemInfo.systemId,
            }
            await api.getTelCode(model)
            this.changeTelCodeButtonText()
            this.$toast({
              message: '验证码发送成功，请注意查收',
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.getTelCodeButtonLoading = false
        }
      },
      // 获取邮箱验证码
      async getEmailCode() {
        try {
          if (this.getTelCodeTimer) return
          if (!this.isCheckError) {
            this.getTelCodeButtonLoading = true
            const model = {
              email: this.loginModel.account,
              type: 16, // 0 注册 1 找回密码 2 实名认证 4 更换邮箱 5.签署验证 15 更换签署密码 16 验证码登录 17 更换登录密码
              systemId: this.systemInfo.systemId,
            }
            await api.getEmailCode(model)
            this.changeTelCodeButtonText()
            this.$toast({
              message: '验证码发送成功，请注意查收',
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.getTelCodeButtonLoading = false
        }
      },
      // 修改获取验证码按钮文本
      changeTelCodeButtonText() {
        const defaultCountDown = this.getTelCodeCountDown
        this.isTelCodeButtonBanned = true
        this.telCodeButtonText = `重新发送(${this.getTelCodeCountDown})`
        this.getTelCodeTimer = setInterval(() => {
          if (this.getTelCodeCountDown === 0) {
            this.isTelCodeButtonBanned = false
            clearInterval(this.getTelCodeTimer)
            this.getTelCodeTimer = null
            this.telCodeButtonText = '获取验证码'
            this.getTelCodeCountDown = defaultCountDown
          } else {
            this.getTelCodeCountDown -= 1
            this.telCodeButtonText = `重新发送(${this.getTelCodeCountDown})`
          }
        }, 1000)
      },

      // 去登录
      async handleLogin() {
        // const isPassed1 = await this.validateField('loginForm', 'account');
        // if (isPassed1) {
        try {
          console.log('handleLogin555555');
          this.loginButtonLoading = true
          this.loginModel.code = Number(this.loginModel.code)
          this.loginModel.systemId = this.systemInfo.systemId
          const res = await this.$store.dispatch('login', this.loginModel)
          if (res) {
            let redirectUrl
            if (this.isZdd) {
              redirectUrl = this.redirect || this.formPath || '/zdd/home'
            } else {
              redirectUrl = this.redirect || this.formPath || '/HomePage'
            }
            console.log('redirectUrl: ', redirectUrl);
            if (this.isZdd && this.code) {
              this.$emit('selectCom')
              return
            }
            // 跳转到身份页面
            if (this.isZdd && !this.userInfo.isCloseHomePageGuide) {
              redirectUrl = '/zdd/createcom'
            }
            await this.$router.push({
              path: redirectUrl,
              query: this.otherQuery,
            })
          }
          // await this.$router
          //   .push({
          //     path: this.redirect || this.formPath || '/',
          //     query: this.otherQuery,
          //   })
          //   .catch(e => e)
        } catch (error) {
          console.error(error)
        } finally {
          this.loginButtonLoading = false
        }
        // }
      },
      async handleShareLogin() {
        try {
          this.loginButtonLoading = true
          this.loginModel.code = Number(this.loginModel.code)
          this.loginModel.systemId = this.systemInfo.systemId
          const params = {
            loginModel: this.loginModel,
            redirect: this.redirect,
          }
          const res = await this.$store.dispatch('shareLogin', params)
          if (res) {
            let redirectUrl
            if (this.isZdd) {
              redirectUrl = this.redirect || this.formPath || '/zdd/home'
            } else {
              redirectUrl = this.redirect || this.formPath || '/HomePage'
            }

            if (this.isZdd && this.code) {
              this.$emit('selectCom')
              return
            }

            // 跳转到身份页面
            if (this.isZdd && !this.userInfo.isCloseHomePageGuide) {
              redirectUrl = '/zdd/createcom'
            }

            await this.$router.push({
              path: redirectUrl,
              query: this.otherQuery,
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loginButtonLoading = false
        }
      },

      async updateEmpowerViewArchives() {
        try {
          const model = { code: this.code }
          const { userId } = await archivesApi.updateApplyViewArchives(model)
          return userId
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .login-component {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .agreement-list {
      margin-top: 8px;
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
      word-break: break-all;
    }
    .form-container {
      .register-form {
        .imgCode-container {
          width: 96px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .description {
          margin-top: 8px;
          font-size: 14px;
          color: @TEXT-COLOR-1;
        }
      }
    }

    .tel-code-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tel-code-title {
        font-size: 22px;
        line-height: 30px;
        color: #333;
        font-weight: 500;
      }
      .tel-code-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          margin-top: 30px;
          font-size: 18px;
          line-height: 22px;
          color: #434751;
          &.bold {
            font-weight: 500;
          }
        }
        .text + .text {
          margin-top: 7px;
        }
      }
      .tel-code-input {
        margin-top: 68px;
        transition: all 0.1s;

        display: flex;
        .tel-code-val {
          display: inline-flex;
          justify-content: center;
          width: 33px;
          height: 33px;
          font-size: 24px;
          border-bottom: 1px solid #eee;
          transition: all 0.1s;

          &.highlight {
            border-bottom: 1px solid #434751;
          }
        }
        .tel-code-val + .tel-code-val {
          margin-left: 17px;
        }
        .login-code-input {
          position: absolute;
          display: flex;
          left: -100px;
          top: -100px;
        }
      }

      .get-code {
        margin-top: 22px;
        font-size: 16px;
        color: @BLUE;

        &.disabled {
          color: @TEXT-COLOR-2;
        }
      }
    }

    .button-container {
      width: 100%;
      position: fixed;
      bottom: 20px;
      left: 0;
      padding: 0 24px;
      box-sizing: border-box;

      /deep/ .van-button {
        width: 100%;

        .van-button__text {
          color: @WHITE;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
</style>
