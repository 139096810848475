<template>
  <div class="login-component">
    <div class="form-container">
      <van-form
        key="loginForm"
        class="register-form"
        ref="loginForm"
        validate-first
        :show-error="false"
      >
        <van-field
          name="account"
          :placeholder="isZdd ? '请输入手机号' : '请输入手机号/邮箱'"
          ref="telephone"
          :clearable="true"
          :show-error="false"
          :error="false"
          :rules="rules.account"
          v-model="loginModel.account"
          @blur="setLoginCode"
        ></van-field>
        <van-field
          name="password"
          placeholder="请输入密码"
          :clearable="true"
          :type="showPassword ? 'text' : 'password'"
          ref="password"
          :show-error="false"
          :error="false"
          :rules="rules.password"
          v-model="loginModel.password"
        >
          <template #button>
            <SvgIcon
              type="eye_open"
              class="icon"
              v-show="showPassword"
              @click="handleChangeShowPassword"
            />
            <SvgIcon
              type="eye_close"
              class="icon"
              v-show="!showPassword"
              @click="handleChangeShowPassword"
            />
          </template>
        </van-field>
        <div class="agreement-list">
          点击登录代表您已阅读并同意<a
            class="link agreement-link"
            v-for="item in agreementList"
            :key="item.title"
            @click="handleShowAgreement(item.title)"
            >《{{ item.title }}》</a
          >
        </div>
      </van-form>
    </div>
    <div class="button-container" v-if="!isPopup">
      <van-button
        type="primary"
        round
        :loading="loginButtonLoading"
        @click="handleLogin"
        >登录</van-button
      >
    </div>
    <Agreement v-model="showAgreement" :title="curAgreementTitle" />
  </div>
</template>

<script>
  import {
    validateMobileNumber,
    validatePassword,
    validateEmail,
  } from '@/utils/validate'
  import { activeElementScrollIntoView } from '@/utils/helper'
  import { mapState } from 'vuex'
  import api from '@/api/passport'
  import Agreement from '@/components/Agreement/index.vue'
  import archivesApi from '@/api/user/archives'

  export default {
    name: 'PasswordLogin',
    components: { Agreement },
    beforeRouteEnter(to, from, next) {
      const formPath = from.path
      next(vm => {
        vm.formPath = formPath
      })
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
      redirect: {
        type: String,
      },
      otherQuery: {
        type: null,
      },
    },
    data() {
      this.agreementList = [
        {
          title: '服务协议',
        },
        {
          title: '隐私协议',
        },
        {
          title: '证书服务协议',
        },
        {
          title: '易云章企业与个人服务协议',
        },
      ]
      return {
        formPath: '',
        rules: {
          account: [
            {
              validator: this.validateAccount,
              trigger: 'onBlur',
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号',
              trigger: 'onBlur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'onBlur',
            },
            {
              validator: this.validatePassword,
              trigger: 'onBlur',
              message: '密码为8-16位的数字、字母组合',
            },
          ],
        },
        loginModel: {
          account: undefined,
          password: undefined,
          openId: window.sessionStorage.openId
            ? window.sessionStorage.openId
            : null,
        },
        // 密码是否展示明文
        showPassword: false,
        loginButtonLoading: false,
        curAgreementTitle: '服务协议',
        showAgreement: false,

        code: undefined,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
      userInfo() {
        return this.$store.getters.getUserInfo
      },
    },
    created() {
      const { code } = this.$route.query
      this.code = code
    },
    mounted() {
      this.loginModel.account = this.$store.getters.loginCode
    },
    methods: {
      setLoginCode() {
        this.$store.commit('setLoginCode', this.loginModel.account)
      },
      handleShowAgreement(title) {
        this.showAgreement = true
        this.curAgreementTitle = title
      },
      // 验证账号
      validateAccount(val) {
        if (this.isZdd) {
          if (this.isMobile(val)) {
            return this.validateMobile(val)
          }
          return false
        }
        if (this.isMobile(val)) {
          return this.validateMobile(val)
        }
        if (this.isEmail(val)) {
          return this.validateEmailAccount(val)
        }
        return false
      },

      // 验证输入的值是手机还是邮箱
      isMobile(value) {
        return /^\d+$/g.test(value)
      },
      isEmail(value) {
        return /[a-zA-Z@]/g.test(value)
      },

      // 验证手机号
      validateMobile(val) {
        return validateMobileNumber(val)
      },
      // 验证邮箱
      validateEmailAccount(val) {
        return validateEmail(val)
      },

      // 验证密码长度
      validatePassword(val) {
        return validatePassword(val)
      },

      // 切换密码是否为明文
      handleChangeShowPassword() {
        this.showPassword = !this.showPassword
      },

      // 验证表单项
      async validateField(formName, name) {
        let isPassed = true
        try {
          await this.resetValidation(formName, name)
          await this.$refs[formName].validate(name)
        } catch (error) {
          isPassed = false
          this.$toast(error)
        }
        return isPassed
      },

      // 重置表单项的验证提示
      async resetValidation(formName, name) {
        try {
          await this.$refs[formName].resetValidation(name)
        } catch (error) {
          this.$toast(error)
        }
      },

      // 去登录
      async handleLogin() {
        const isPassed1 = await this.validateField('loginForm', 'account')
        const isPassed2 = await this.validateField('loginForm', 'password')
        if (isPassed1 && isPassed2) {
          try {
            this.loginButtonLoading = true
            this.loginModel.systemId = this.systemInfo.systemId
            const res = await this.$store.dispatch('login', this.loginModel)
            if (res) {
              let redirectUrl
              if (this.isZdd) {
                redirectUrl = this.redirect || this.formPath || '/zdd/home'
              } else {
                redirectUrl = this.redirect || this.formPath || '/HomePage'
              }
              if (this.isZdd && this.code) {
                this.$emit('selectCom')
                return
              }
              // 跳转到身份页面
              if (this.isZdd && !this.userInfo.isCloseHomePageGuide) {
                redirectUrl = '/zdd/createcom'
              }
              this.$router
                .push({
                  path: redirectUrl,
                  query: this.otherQuery,
                })
                .catch(e => e)
            }
          } catch (error) {
            console.error(error)
          } finally {
            this.loginButtonLoading = false
          }
        }
      },
      async updateEmpowerViewArchives() {
        try {
          const model = { code: this.code }
          const { userId } = await archivesApi.updateApplyViewArchives(model)
          return userId
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .login-component {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .agreement-list {
      margin-top: 8px;
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
      word-break: break-all;
    }
    .form-container {
      .check-form {
        .imgCode-container {
          width: 96px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        /deep/ .van-checkbox {
          margin-top: 15px;
          line-height: 24px;

          .van-checkbox__icon {
            width: 16px;
            height: 16px;

            .van-icon {
              width: 16px;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .button-container {
      width: 100%;
      padding: 0 24px;
      position: fixed;
      bottom: 20px;
      left: 0;

      /deep/ .van-button {
        width: 100%;

        .van-button__text {
          color: @WHITE;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
</style>
