<template>
  <div class="register-page">
    <div class="top" v-if="showDescription">
      <div class="description">
        <div class="hello">你好，</div>
        <div class="content">
          <span class="text">欢迎来到{{ systemInfo.company }}</span>
          <a class="link" @click="handleChangeTab(1)" v-if="tabIndex === 0"
            >验证码登录</a
          >
          <a class="link" @click="handleChangeTab(0)" v-if="tabIndex === 1"
            >密码登录</a
          >
        </div>
      </div>
    </div>
    <div class="main">
      <PasswordLogin
        :isPopup="isPopup"
        :redirect="redirect"
        :otherQuery="otherQuery"
        v-if="tabIndex === 0"
        @selectCom="handleShowSelectIdentityDialog"
      />
      <CodeLogin
        :isPopup="isPopup"
        :redirect="redirect"
        :otherQuery="otherQuery"
        v-if="tabIndex === 1"
        @selectCom="handleShowSelectIdentityDialog"
      />
    </div>

    <van-dialog
      v-model="selectIdentityDia"
      title="提示"
      show-cancel-button
      :before-close="onConfirm"
    >
      <div class="select-wrap">
        <div class="select-title">请选择您的企业身份</div>
        <ul class="select-list">
          <li
            v-for="(item, index) in companyList"
            :key="index"
            @click="onSelectCom(item.comId)"
            :class="
              item.comId === curSelectComId
                ? 'select-item-active'
                : 'select-item'
            "
          >
            {{ item.comName }}
          </li>
          <li class="select-item-created" @click="handleCreateCom">
            创建企业
          </li>
        </ul>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { resetCookies } from '@/utils/cookies'
  import archivesApi from '@/api/user/archives'
  import PasswordLogin from './components/PasswordLogin.vue'
  import CodeLogin from './components/CodeLogin.vue'

  export default {
    name: 'Login',
    provide() {
      return {
        handleToggleShowDescription: this.handleToggleShowDescription,
      }
    },
    components: {
      PasswordLogin,
      CodeLogin,
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 是否展示上边的描述
        showDescription: 1,
        tabIndex: 1,
        redirect: undefined,
        otherQuery: undefined,
        curSelectComId: undefined,

        // 展示选择企业身份dialog
        selectIdentityDia: false,
        code: undefined,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      companyList() {
        return this.$store.getters.companyList
      },
    },
    filters: {},
    created() {
      this.$store.dispatch('resetSession')
      const { query } = this.$route
      if (query) {
        this.redirect = query.redirect
        this.code = query.code
        this.otherQuery = this.getOtherQuery(query)
      }
    },
    methods: {
      getOtherQuery(query) {
        return Object.keys(query).reduce((prev, cur) => {
          if (cur !== 'redirect') {
            prev[cur] = query[cur]
          }
          return prev
        }, {})
      },
      handleChangeTab(val) {
        this.tabIndex = val
      },
      handleToggleShowDescription(value) {
        this.showDescription = value
      },

      async updateEmpowerViewArchives() {
        try {
          const model = { code: this.code }
          const { userId } = await archivesApi.updateEmpowerViewArchives(model)
          return userId
        } catch (error) {
          console.error(error)
        }
      },

      onSelectCom(comId) {
        this.curSelectComId = comId
      },

      // 选择企业身份确认，先切换身份，然后跳转到查看详情页面
      async onConfirm(action, done) {
        if (action === 'confirm') {
          let loadingInstance
          try {
            loadingInstance = this.$toast.loading({
              message: '处理中',
              loadingType: 'spinner',
            })
            if (!this.curSelectComId) return
            await this.$store.dispatch(
              'updateCurrentIdentity',
              this.curSelectComId
            )
            const userId = await this.updateEmpowerViewArchives()
            if (userId) {
              this.$router.replace({
                name: 'ZddDetail',
                query: {
                  userId,
                },
              })
            } else {
              this.$router.replace({
                name: 'ZddHome',
              })
            }
          } catch (error) {
            console.error(error)
          } finally {
            if (loadingInstance) {
              loadingInstance.close()
            }
            done()
          }
        } else {
          done()
          this.$router.replace({
            name: 'ZddHome',
          })
        }
      },

      handleShowSelectIdentityDialog() {
        this.selectIdentityDia = true
      },

      handleCreateCom() {
        this.$router.replace({
          name: 'CreateCompany',
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .register-page {
    width: 100vw;
    overflow: hidden;
    // height: 100vh;
    // min-height: 100vh;
    // height: -webkit-fill-available;
    // height: 100%;
    // max-height: 100%;
    // height: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 63px 24px 24px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .top {
      .description {
        font-weight: 400;
        margin-bottom: 43px;

        .hello {
          font-size: 36px;
          text-align: left;
          color: @TEXT-COLOR-0;
        }

        .content {
          margin-top: 21px;
          font-size: 18px;
          color: @TEXT-COLOR-1;

          .link {
            margin-left: 8px;
            color: @BLUE;
          }
        }
      }
    }
    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  /deep/ .van-form {
    .van-cell {
      margin-top: 15px;
      padding: 0;
      font-size: 18px;
      color: @TEXT-COLOR-0;

      &.van-field--error .van-field__control,
      .van-field--error .van-field__control::placeholder {
        color: @TEXT-COLOR-0;
        // font-size: 16px;
      }

      &::after {
        display: none;
      }

      .van-field__body {
        border-bottom: 1px solid @BORDER-COLOR-0;
        padding: 14px 0;
      }

      .van-field__error-message {
        font-size: 12px;
        color: @RED;
      }
    }
  }

  .select-wrap {
    .select-title {
      width: 227px;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #677283;
      line-height: 22px;
      margin: 0 auto 24px;
    }
    .select-list {
      max-height: 220px;
      overflow-y: auto;
      .select-item {
        padding: 8px;
        width: 238px;
        border: 1px solid #e2e4ea;
        border-radius: 20px;
        margin: 0 auto 16px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #111a34;
      }
      .select-item-active {
        padding: 8px;
        width: 238px;
        border: 1px solid #1676ff;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #1676ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 16px;
        &::after {
          flex-shrink: 0;
          content: '';
          width: 15px;
          height: 10px;
          margin-left: 4px;
          margin-top: 3px;
          background: url('../../assets/imgs/ios2x_路径 2@2x.png') no-repeat
            center;
          background-size: 15px 10px;
        }
      }
      .select-item-created {
        padding: 8px;
        width: 238px;
        // height: 40px;
        border: 1px solid #1676ff;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        background: #1676ff;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 16px;
        &::before {
          flex-shrink: 0;
          content: '';
          width: 12px;
          height: 12px;
          margin-right: 4px;
          margin-top: 2px;
          background: url('../../assets/imgs/sealManage/add.png') no-repeat
            center;
          background-size: 12px 12px;
        }
      }
    }
  }
</style>
